import React, { useState } from 'react';
import './App.css';
import BeachMap from './BeachMap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const [inputValue, setInputValue] = useState(''); // Local state to track input field value
    const [searchTerm, setSearchTerm] = useState(''); // State used for searching
    const [searchCategory, setSearchCategory] = useState('beach_name');

    const handleSearch = () => {
        setSearchTerm(inputValue); // Update searchTerm with the current value when search is triggered
    };

    // Call handleSearch when Enter key is pressed
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="App">
            <nav className="navbar navbar-expand-lg navbar-custom">
                <a className="navbar-brand" href="#">
                    <img src="/Virginia-Tech-Logo.png" alt="Virginia Tech Logo" className="vt-logo" />
                    Red Tide Respiratory Irritation Forecasting System
                </a>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <select className="form-control" onChange={(e) => setSearchCategory(e.target.value)}>
                                <option value="beach_name">Beach Name</option>
                                <option value="seaweed_level">Seaweed Level</option>
                            </select>
                        </li>
                        <li className="nav-item">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </li>
                        <li className="nav-item">
                            <button className="btn btn-primary" onClick={handleSearch}>Search</button>
                        </li>
                    </ul>
                </div>
            </nav>
            <BeachMap searchTerm={searchTerm} searchCategory={searchCategory} />
        </div>
    );
}

export default App;
