/* global google */
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import './BeachMap.css';

function BeachMap({ searchTerm, searchCategory }) {
    const mapRef = useRef(null);
    const markers = useRef([]);

    useEffect(() => {
        const map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: 39.8283, lng: -98.5795 },
            zoom: 4
        });
        mapRef.current = map;

        function fetchAndDisplayBeaches() {
            axios.get('https://api.floridaredtideforecast.com/api/beachData')
                .then(response => {
                    // Remove existing markers from the map
                    markers.current.forEach(marker => marker.setMap(null));
                    markers.current = [];

                    const filteredData = response.data.filter(beach => {
                        if (searchCategory === 'beach_name') {
                            return beach.beach_name.toLowerCase().includes(searchTerm.toLowerCase());
                        } else if (searchCategory === 'seaweed_level') {
                            return beach.seaweed_level.toLowerCase() === searchTerm.toLowerCase();
                        }
                        return true;
                    });

                    // Now create markers for the filtered data
                    filteredData.forEach(beach => {
                        const { latitude, longitude, seaweed_level, beach_name } = beach;
                        let markerColor;
                        switch (seaweed_level) {
                            case 'high':
                                markerColor = 'red';
                                break;
                            case 'medium':
                                markerColor = 'orange';
                                break;
                            case 'low':
                                markerColor = 'green';
                                break;
                            default:
                                markerColor = 'blue';
                        }

                        const marker = new google.maps.Marker({
                            position: { lat: latitude, lng: longitude },
                            map: map,
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: markerColor,
                                fillOpacity: 1,
                                strokeWeight: 0,
                                scale: 5
                            }
                        });

                        const infoWindow = new google.maps.InfoWindow({
                            content: `<b>${beach_name}</b><br>Seaweed Level: ${seaweed_level}`
                        });

                        marker.addListener('click', () => {
                            infoWindow.open(map, marker);
                        });

                        markers.current.push(marker);
                    });
                })
                .catch(error => {
                    console.error('Error fetching the beach data', error);
                });
        }

        // Initial fetch and display of beaches
        fetchAndDisplayBeaches();

        // Cleanup function to remove markers when the component unmounts
        return () => {
            markers.current.forEach(marker => marker.setMap(null));
            markers.current = [];
        };
    }, [searchTerm, searchCategory]); // Re-run effect when searchTerm or searchCategory changes

    return (
        <div id="map"></div>
    );
}

export default BeachMap;
